import { Component } from '@angular/core';

@Component({
  selector: 'app-provinces',
  templateUrl: './provinces.component.html',
  styleUrl: './provinces.component.scss'
})
export class ProvincesComponent {
  regions = [
    {
      name: 'ภาคเหนือ',
      provinces: ['เชียงใหม่', 'เชียงราย', 'ลำปาง', 'ลำพูน', 'น่าน', 'พะเยา', 'แพร่', 'แม่ฮ่องสอน', 'อุตรดิตถ์', 'ตาก', 'สุโขทัย', 'พิษณุโลก', 'เพชรบูรณ์', 'พิจิตร', 'กำแพงเพชร']
    },
    {
      name: 'ภาคตะวันออกเฉียงเหนือ',
      provinces: ['ขอนแก่น', 'นครราชสีมา', 'อุบลราชธานี', 'อุดรธานี', 'บุรีรัมย์', 'สุรินทร์', 'ศรีสะเกษ', 'มหาสารคาม', 'ร้อยเอ็ด', 'กาฬสินธุ์', 'ชัยภูมิ', 'หนองคาย', 'สกลนคร', 'นครพนม', 'ยโสธร', 'อำนาจเจริญ', 'มุกดาหาร', 'หนองบัวลำภู', 'บึงกาฬ']
    },
    {
      name: 'ภาคกลาง',
      provinces: ['กรุงเทพมหานคร', 'นนทบุรี', 'ปทุมธานี', 'พระนครศรีอยุธยา', 'สระบุรี', 'ลพบุรี', 'สมุทรปราการ', 'สมุทรสาคร', 'สมุทรสงคราม', 'นครปฐม', 'ราชบุรี', 'กาญจนบุรี', 'เพชรบุรี', 'ประจวบคีรีขันธ์', 'สิงห์บุรี', 'อ่างทอง', 'ชัยนาท', 'สุพรรณบุรี']
    },
    {
      name: 'ภาคตะวันออก',
      provinces: ['ชลบุรี', 'ระยอง', 'จันทบุรี', 'ตราด', 'ฉะเชิงเทรา', 'ปราจีนบุรี', 'สระแก้ว', 'นครนายก']
    },
    {
      name: 'ภาคตะวันตก',
      provinces: ['กาญจนบุรี', 'ราชบุรี', 'เพชรบุรี', 'ประจวบคีรีขันธ์', 'ตาก']
    },
    {
      name: 'ภาคใต้',
      provinces: ['ภูเก็ต', 'กระบี่', 'สุราษฎร์ธานี', 'นครศรีธรรมราช', 'สงขลา', 'ปัตตานี', 'ยะลา', 'นราธิวาส', 'ตรัง', 'พัทลุง', 'ชุมพร', 'ระนอง', 'สตูล', 'พังงา']
    }
  ];
}
