<div class="hero-slider-three owl-carousel owl-theme">
    <div class="hero-slider-three-item item-house">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center mt-50">
                        <div class="col-lg-12 col-md-12">
                            <div class="slider-three-text">
                                <br><br><br>
                                <span>หจก. 4 คิงส์ ขนส่ง</span>
                                <h2> รถรับจ้างขนของ พร้อมคนยก</h2>
                                <p>หากคุณกำลังมองหาบริการรถกระบะรับจ้างสำหรับย้ายบ้าน ย้ายหอ พร้อมคนช่วยขนของ
                                    ถอดประกอบเฟอร์นิเจอร์ หรือขนย้ายสินค้าทั่วไป บริการของหจก. 4คิงส์ ขนส่ง
                                    พร้อมตอบโจทย์ทุกความต้องการ ด้วยบริการด่วนทั่วประเทศ ตลอด 24 ชั่วโมง
                                    ไม่ว่าจะเป็นการย้ายบ้าน ห้องพัก คอนโด หรือสำนักงาน ด้วยรถกระบะตู้ทึบ รถสี่ล้อใหญ่
                                    รถหกล้อ หรือรถเฮี๊ยบ ทีมงานมืออาชีพพร้อมให้บริการ
                                    รวมถึงบริการเก็บเงินปลายทางและถอดประกอบเฟอร์นิเจอร์ฟรี</p>
                                <a href="https://lin.ee/GrZqXTl"><img src="assets/img/line.gif"
                                        alt="รถรับจ้างขนของ พร้อมคนยก" class="linebtn"></a>
                                <a href="tel:0917762068" class="default-btn-one">ประเมินราคาฟรี กดโทรที่นี่!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hero-slider-three-item item-bike ">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center mt-50">
                        <div class="col-lg-12 col-md-12">
                            <div class="slider-three-text">
                                <br><br><span>หจก. 4 คิงส์ ขนส่ง</span>
                                <h2> รถรับจ้างขนย้ายบิ๊กไบค์ ส่งมอไซค์ไปต่างจังหวัด</h2>
                                <p>หากคุณต้องการบริการส่งรถมอเตอร์ไซค์ ไม่ว่าจะเป็นมอเตอร์ไซค์ทั่วไปหรือบิ๊กไบค์
                                    ทั้งในพื้นที่หรือข้ามจังหวัด หจก. 4คิงส์ ขนส่ง พร้อมบริการขนย้ายมอเตอร์ไซค์ทุกประเภท
                                    ด้วยความเชี่ยวชาญในการขนย้ายของหนัก พร้อมห่อหุ้มกันรอยขีดข่วน
                                    และมีประกันการขนส่งสูงสุดถึง 1 ล้านบาท บริการด่วน 24 ชั่วโมง ส่งถึงหน้าบ้าน
                                    สะดวกสบายและมั่นใจทุกขั้นตอน</p>
                                <a href="https://lin.ee/GrZqXTl"><img src="assets/img/line.gif"
                                        alt="รถรับจ้างขนของ พร้อมคนยก" class="linebtn"></a>
                                <a href="tel:0917762068" class="default-btn-one">ประเมินราคาฟรี กดโทรที่นี่!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hero-slider-three-item item-pet">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center mt-50">
                        <div class="col-lg-12 col-md-12">
                            <div class="slider-three-text">
                                <br><br><span>หจก. 4 คิงส์ ขนส่ง</span>
                                <h2>
                                    รถรับจ้างฝากส่งสัตว์เลี้ยง</h2>
                                <p>หากคุณกำลังมองหารถรับจ้างฝากส่งสัตว์เลี้ยงราคามิตรภาพ ส่งน้องหมา น้องแมวราคาถูก
                                    พร้อมบริการส่งด่วน อีกทั้งยังรับส่งสัตว์เลี้ยงถึงหน้าบ้าน ติดต่อเรา หจก. 4คิงส์
                                    ขนส่งได้เลย ทางเราพร้อมยินดีให้บริการ ตลอด 24ชม.
                                    พร้อมบริการรับส่งสัตว์เลี้ยงข้ามจังหวัด ไม่ว่าจะเป็นสุนัข แมว นก หนู
                                    กระต่ายหรือสัตว์เลี้ยงชนิดต่างๆ
                                    ทางเราพร้อมบริการรับ-ส่งถึงหน้าบ้านคุณพร้อมบริการเก็บเงินปลายทาง</p>
                                <a href="https://lin.ee/GrZqXTl"><img src="assets/img/line.gif"
                                        alt="รถรับจ้างขนของ พร้อมคนยก" class="linebtn"></a>
                                <a href="tel:0917762068" class="default-btn-one">ประเมินราคาฟรี กดโทรที่นี่!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hero-slider-three-item item-industry">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center mt-50">
                        <div class="col-lg-12 col-md-12">
                            <div class="slider-three-text">
                                <br><br><span>หจก. 4 คิงส์ ขนส่ง</span>
                                <h2>รถ 6 ล้อรับจ้าง
                                </h2>
                                <p>หากคุณกำลังมองหารถหกล้อรับจ้างราคาถูก
                                    เพื่อใช้ในการส่งสินค้าขนาดใหญ่หรือส่งพัสดุขนาดใหญ่ ส่งสินค้าอุตสาหกรรม
                                    ส่งสินค้าโรงงาน ส่งเครื่องจักรอุตสาหกรรมหรือส่งเครื่องจักรโรงงาน
                                    จ ด้วยรถ6ล้อจัมโบ้หรือรถเฮี๊ยบ ทางเราก็พร้อมบริการขนส่งทั่วประเทศ พร้อม
                                    wrap สินค้ากันรอย กันกระแทกอย่างดี หรือจะเป็นบริการยกรถเสียด่วน
                                    ทางเราก็ยินดีให้บริการ</p>
                                <a href="https://lin.ee/GrZqXTl"><img src="assets/img/line.gif"
                                        alt="รถรับจ้างขนของ พร้อมคนยก" class="linebtn"></a>
                                <a href="tel:0917762068" class="default-btn-one">ประเมินราคาฟรี กดโทรที่นี่!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hero-slider-three-item item-boots">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center mt-50">
                        <div class="col-lg-12 col-md-12">
                            <div class="slider-three-text">
                                <br><br><span>หจก. 4 คิงส์ ขนส่ง</span>
                                <h2>รับจ้างย้ายบูธการแสดง จัดบูธงานอีเว้นท์
                                </h2>
                                <p>รับจ้างขนของย้ายบูธจัดแสดง จัดบูธงานอีเว้นท์ตามงานต่างๆ ด้วยรถกระตู้ทึบจัมโบ้
                                    หกล้อใหญ่พร้อมบริการซีนกันรอย ให้งานหนักเป็นของเรา บริการย้ายบูธทั่วไทย
                                    เรียกใช้หจก.4คิงส์ได้เลย
                                </p>
                                <a href="https://lin.ee/GrZqXTl"><img src="assets/img/line.gif"
                                        alt="รถรับจ้างขนของ พร้อมคนยก" class="linebtn"></a>
                                <a href="tel:0917762068" class="default-btn-one">ประเมินราคาฟรี กดโทรที่นี่!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Hero Slider Area -->

<!-- Services Area -->

<div class="services-area services-two pb-100">
    <div class="container">
        <div class="section-title">
            <span>รถรับจ้างของเรา</span>
            <h2>ประเภทรถรับจ้างที่เรามีให้บริการ</h2>
        </div>

        <div class="services-slider owl-carousel owl-theme">
            <div class="service-card">
                <div class="image-container">
                    <img src="assets/img/services/1.webp" alt="รถกระบะตู้ทึบรับจ้าง" loading="lazy">
                </div>
                <h3>รถกระบะตู้ทึบ</h3>
                <p>ขนาด 1.50 x 2.20 x 2.10 ม.</p>
                <p>น้ำหนักที่บรรทุกไม่เกิน 1,500 กก.</p>
            </div>

            <div class="service-card">
                <div class="image-container">
                    <img src="assets/img/services/3.webp" alt="รถกระบะคอกรับจ้าง" loading="lazy">
                </div>
                <h3>รถกระบะคอก</h3>
                <p>ขนาด 1.65 x 2.30 x 2.10 ม.</p>
                <p>น้ำหนักที่บรรทุกไม่เกิน 2,500 กก.</p>
            </div>

            <div class="service-card">
                <div class="image-container">
                    <img src="assets/img/services/2.webp" alt="รถบรรทุก 4 ล้อใหญ่รับจ้าง" loading="lazy">
                </div>
                <h3>รถบรรทุก 4 ล้อใหญ่</h3>
                <p>ขนาด 1.80 x 3.20 x 2.30 ม.</p>
                <p>น้ำหนักที่บรรทุกไม่เกิน 4,000 กก.</p>
            </div>

            <div class="service-card">
                <div class="image-container">
                    <img src="assets/img/services/4.webp" alt="รถ6ล้อขนส่ง" loading="lazy">
                </div>
                <h3>รถบรรทุก 6 ล้อคอก</h3>
                <p>ขนาด 2.30 x 6.50 x 2.80 ม.</p>
                <p>น้ำหนักที่บรรทุกไม่เกิน 6,000 กก.</p>
            </div>

            <div class="service-card">
                <div class="image-container">
                    <img src="assets/img/services/5.webp" alt="รถเฮี๊ยบรับจ้าง" loading="lazy">
                </div>
                <h3>รถเฮี๊ยบ</h3>
                <p>ขนาด 2.30 x 12.00 x 2.80 ม.</p>
                <p>น้ำหนักที่บรรทุกไม่เกิน 15,000 กก.</p>
            </div>
        </div>
    </div>
</div>

<!-- End Services Area -->


<!-- Services Slider Area -->
<div class="freight-area freight-area-two ptb-100">
    <div class="container">
        <div class="section-title">
            <span>บริการของเรา</span>
            <h2>พร้อมด้วยบริการขนส่งทุกรูปแบบ</h2>
        </div>

        <div class="services-slider-two owl-carousel owl-theme">
            <div class="service-card-two">
                <img class="freight-image" src="assets/img/freight/1.webp" alt="บริการขนของย้ายบ้าน ย้ายหอ"
                    loading="lazy">
                <div class="service-caption">
                    <h3>บริการขนของย้ายบ้าน ย้ายหอ</h3>
                    <p>รถรับจ้างขนของ ของเราครอบคลุมทุกบริการ ดังนี้ ย้ายห้อง ย้ายหอ ย้ายคอนโด ย้ายครัว ย้ายตู้เย็น
                        ย้ายออฟฟิศ สำนักงานและอื่นๆ</p>
                    <a routerLink="/services-details/housemover" class="default-btn-two"
                        alt="ข้อมูลเพิ่มเติม บริการขนของย้ายบ้าน">ข้อมูลเพิ่มเติม</a>
                </div>
            </div>

            <div class="service-card-two">
                <img class="freight-image" src="assets/img/freight/2.webp" alt="บริการขนย้ายมอไซค์ บิ๊กไบค์"
                    loading="lazy">
                <div class="service-caption">
                    <h3>บริการขนย้ายมอไซค์ บิ๊กไบค์</h3>
                    <p>บริการขนย้ายบิ๊กไบค์ ส่งมอไซค์ไปต่างจังหวัด ของเรา ทางเราพร้อม wrap ให้อย่างดี
                        ไม่ต้องกลัวรถของคุณเป็นรอย
                        ส่งตรงถึงหน้าบ้าน</p>
                    <a routerLink="/services-details/bigbiketransport" class="default-btn-two"
                        alt="ข้อมูลเพิ่มเติม บริการขนย้ายมอไซค์ บิ๊กไบค์">ข้อมูลเพิ่มเติม</a>
                </div>
            </div>

            <div class="service-card-two">
                <img class="freight-image" src="assets/img/freight/3.webp" alt="บริการรับส่งสัตว์เลี้ยง" loading="lazy">
                <div class="service-caption">
                    <h3>บริการรับส่งสัตว์เลี้ยง</h3>
                    <p>บริการรับส่งสัตว์เลี้ยงของเราไม่ว่าจะไกลแค่ไหน เราพร้อมดูแลสัตว์เลี้ยงของคุณตลอดการเดินทาง
                        พร้อมบริการรับ-ส่งถึงหน้าบ้านคุณ</p>
                    <a routerLink="/services-details/petdelivery" class="default-btn-two"
                        alt="ข้อมูลเพิ่มเติม บริการรับส่งสัตว์เลี้ยง">ข้อมูลเพิ่มเติม</a>
                </div>
            </div>

            <div class="service-card-two">
                <img class="freight-image" src="assets/img/freight/4.webp" alt="บริการรับส่งสินค้า พัสดุ"
                    loading="lazy">
                <div class="service-caption">
                    <h3>บริการรับส่งสินค้า พัสดุ</h3>
                    <p>บริการรถรับส่งพัสดุของเรา ไม่ว่าจะชิ้นเล็ก-ใหญ่หรือสินค้าอุตสาหกรรม เครื่องจักรอุตสาหกรรม
                        เครื่องจักรโรงงาน เราก็พร้อมให้บริการ</p>
                    <a routerLink="/services-details/parceltransport" class="default-btn-two"
                        alt="ข้อมูลเพิ่มเติม บริการรับส่งสินค้า พัสดุ">ข้อมูลเพิ่มเติม</a>
                </div>
            </div>
            <div class="service-card-two">
                <img class="freight-image" src="assets/img/freight/boot1.webp" alt="บริการรับส่งสินค้า พัสดุ"
                    loading="lazy">
                <div class="service-caption">
                    <h3>บริการขนย้ายบูธออกงานอีเว้นท์</h3>
                    <p>บริการรถรับจ้างสำหรับการขนย้ายอุปกรณ์จัดแสดงสินค้าตามงานอีเว้นท์ต่างๆ ย้ายบูธงานแสดงสินค้า
                        exhibition พร้อมถอดประกอบ</p>
                    <a routerLink="/services-details/exhibition" class="default-btn-two"
                        alt="ข้อมูลเพิ่มเติม บริการขนย้ายงานจัดแสดงสินค้า">ข้อมูลเพิ่มเติม</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Slider Area -->

<!-- About Area -->
<div class="about-area-two ptb-100 ">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-contant-others">
                    <div class="section-title">
                        <span>เกี่ยวกับเรา</span>
                        <h2>หจก 4คิงส์ ขนส่ง คืออะไร?</h2>
                    </div>

                    <div class="about-two-text">
                        <p>หจก 4คิงส์ ขนส่งคือผู้ให้บริการรถกระบะรับจ้างขนของ พร้อมคนยก
                            อีกทั้งยังเต็มไปด้วยบริการที่หลากหลาย ไม่ว่าจะเป็นการย้ายบ้าน ย้ายห้อง ย้ายหอพัก ย้ายคอนโด
                            ย้ายครัว ขนส่งตู้เย็น ขนของ ส่งของ ย้ายสำนักงาน พร้อมบริการถอดประกอบเฟอร์นิเจอร์</p>
                        <p>
                            อีกทั้งบริการส่งมอไซค์ไปต่างจังหวัด หรือจะยกรถเสีย ไม่ว่าจะเป็นรถเก๋ง รถกระบะ มอเตอร์ไซค์
                            บิ๊กไบค์ หรือแม้กระทั่งรถสปอร์ต ทางเราก็ยินดีให้บริการ
                        </p>
                        <p>พร้อมทั้งบริการรับส่งสัตว์เลี้ยงไปต่างจังหวัด ไม่ว่าจะขึ้นเหนือหรือลงใต้
                            ทางเราก็ยินดีให้บริการ ไม่ว่าจะเป็นสุนัข แมว นก หนู กระต่าย กระรอก หรือสัตว์เลี้ยงอื่นๆ
                            โดยที่น้องๆ จะได้นั่งหน้าไปกับพี่ๆคนขับอย่างแน่นอน สบายใจหายห่วง</p>
                        <p>หรือจะเป็นบริการรถรับส่งของ ส่งสินค้าพัสดุชิ้นเล็ก-ใหญ่หรือสินค้าอุตสาหกรรม
                            เครื่องจักรอุตสาหกรรมทางเราก็มีบริการ
                            โดยลูกค้าที่เป็นลูกค้าบริษัทสามารถเลือกใช้บริการส่งสินค้าเป็นรายครั้ง หรือรายประจำ
                            ทางเราก็ยินดีให้บริการอย่างเต็มที่</p>
                        <br>
                        <a href="https://lin.ee/GrZqXTl" class="default-btn-one mr-3"
                            alt="บริการรับส่งสัตว์เลี้ยง ขนย้ายสัตว์เลี้ยงข้ามจังหวัด">สอบถามราคา</a>
                        <div class="watch-video">
                            <div class="video-btn">
                                <a href="https://www.youtube.com/watch?v=QLM9W53zXLk" class="popup-youtube"><i
                                        class="bx bx-play whiteText"></i> ดูวิดีโอตัวอย่าง</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pt-100">
                <div class="about-two-img">
                    <br>
                    <img src="assets/img/logo.webp" alt="เกี่ยวกับเรา หจก. 4คิงส์" loading="lazy">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Area -->


<!-- Choose Us Area -->
<div class="choose-us-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-us-image">
                    <img src="assets/img/choose/choose3.webp" alt="รถรับจ้าง" loading="lazy">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="choose-content">
                    <div class="choose-us-text">
                        <div class="section-title">
                            <span>จุดเด่นของบริการของเรา</span>
                            <h2>เรามุ่งเน้นด้วยการบริการคุณภาพ</h2>
                        </div>
                    </div>
                    <p>หจก 4คิงส์
                        ขนส่งเป็นบริษัทรถรับจ้างขนของ ส่งมอไซค์ไปต่างจังหวัด ด้วยกระบะคอก ตู้ทึบ จัมโบ้ รถหกล้อรับจ้าง
                        18 ล้อ รับ-ส่งของทั่วไทย ข้อดีของการใช้บริการกับทาง หจก 4คิงส์ ขนส่ง มี4ข้อ ดังนี้
                    </p>
                    <p> ความปลอดภัย: การประกันภัยสำหรับสินค้าของท่าน
                        เพื่อให้ลูกค้ามั่นใจว่าสินค้าหรือพาหนะของท่านจะถูกจัดส่งอย่างปลอดภัยไร้รอยด้วยการซีลกันรอยและล๊อคพัสดุกันกระแทกอย่างแน่นหนา พร้อมรับประกันความเสียหายของสินค้าเต็มราคา
                    </p>
                    <p> ความสะดวกสบาย: บริการรับ-ส่งสินค้าที่ต้องการถึงหน้าบ้านพร้อมคนยก ถอด-ประกอบเฟอร์นิเจอร์
                        จึงมั่นใจได้ว่าการย้ายบ้านหรือการส่งสินค้าของคุณ จะเป็นงานที่ง่ายขึ้นกว่าเดิม</p>
                    <p> การบริการที่รวดเร็ว:
                        ทีมงานมีประสบการณ์และความเชี่ยวชาญในการจัดส่งสินค้าและพาหนะรวมถึงเครือข่ายบริการที่ครอบคลุมทั่วประเทศ
                        พร้อมเทคโนโลยีนำทางช่วยให้สามารถจัดส่งสินค้าให้ถึงที่หมายได้อย่างรวดเร็ว
                        อีกทั้งลูกค้ายังสามารถนัดหมายรับสินค้าเพื่อพร้อมส่งในวันเดียวกัน</p>
                    <p> ค่าบริการที่เหมาะสม: การคิดค่าบริการอย่างเหมาะสมพร้อมคุณภาพที่ราคาเริ่มต้นเพียง 1,500 บาท
                        โดยคำนวณจากจำนวนสิ่งของและระยะทางจึงเชื่อมั่นได้ว่าลูกค้าได้ค่าบริการที่เหมาะสมที่สุด
                    </p>
                    <ul>
                        <li><i class='bx bx-check'></i> รับส่งทุกพื้นที่ทั่วไทย ตลอด 24 ชม.</li>
                        <li><i class='bx bx-check'></i> สะดวกสบาย รับส่งถึงหน้าบ้าน พร้อมบริการเก็บเงินปลายทาง</li>
                        <li><i class='bx bx-check'></i> ขนส่งรวดเร็วและปลอดภัย พร้อมซีลสินค้ากันรอย ฟรี</li>
                        <li><i class='bx bx-check'></i> รับประกันสินค้าเต็มราคา สูงถึง 1 ล้านบาท</li>
                    </ul>
                    <a href="https://lin.ee/GrZqXTl"><img src="assets/img/line.gif" alt="รับส่งทั่วไทย" class="linebtn"
                            loading="lazy"></a>
                    <a routerLink="/contact" class="default-btn-two">ติดต่อเรา</a>
                </div>
            </div>


        </div>
    </div>
</div>
<!-- End Choose Us Area -->

<app-provinces></app-provinces>

<!-- Gallery Area -->
<div class="gallery-area pt-100">
    <div class="container">
        <div class="choose-us-text">
            <div class="section-title">
                <span>ลูกค้าส่วนหนึ่งของเรา</span>
                <h2>ติดตามอัพเดตผลงานล่าสุดของเราได้ทั้งช่องทาง <a
                        href="https://www.facebook.com/%E0%B8%AB%E0%B8%88%E0%B8%814-%E0%B8%84%E0%B8%B4%E0%B8%87%E0%B8%AA%E0%B9%8C-%E0%B8%82%E0%B8%99%E0%B8%AA%E0%B9%88%E0%B8%87-107051121915840/"
                        target="_blank" alt="เพจ facebook หจก 4คิงส์ จำกัด" aria-label="our facebook page" class="facebook-icon"><i
                            class='bx bxl-facebook'></i></a>&nbsp;
                    <a href="https://www.youtube.com/channel/UCAmlp7p0RKD4oFRQO8DKMcg" target="_blank"
                        alt="ช่อง youtube หจก 4คิงส์ จำกัด" aria-label="our youtube page" class="youtube-icon"><i
                            class='bx bxl-youtube'></i></a>&nbsp;
                    <a href="https://www.tiktok.com/@4kingtransport" target="_blank" alt="ช่อง tiktok หจก 4คิงส์ จำกัด"
                        aria-label="our tiktok page" class="tiktok-icon"><i class='bx bxl-tiktok tiktok-icon' ></i></a>
                </h2>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery-b">
                    <img src="assets/img/gallery/hiub3.webp" alt="ย้ายบ้าน ย้ายครัว" loading="lazy">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery-b">
                    <img src="assets/img/gallery/industry16.webp" alt="ส่งสินค้าขนาดใหญ่" loading="lazy">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery-b">
                    <img src="assets/img/gallery/main3.webp" alt="ฝากส่งบิ๊กไบค์" loading="lazy">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery-b">
                    <img src="assets/img/gallery/house12.webp" alt="รถเฮี๊ยบ" loading="lazy">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery-b">
                    <img src="assets/img/gallery/house15.webp" alt="ส่งพัสดุด่วน" loading="lazy">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery-b">
                    <img src="assets/img/gallery/house14.webp" alt="ขนย้ายบู๊ทออกงาน exhibition" loading="lazy">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery-b">
                    <img src="assets/img/gallery/main4.webp" alt="ฝากส่งสินค้าทั่วไป" loading="lazy">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery-b">
                    <img src="assets/img/gallery/main5.webp" alt="ส่งของ สินค้าโรงงาน" loading="lazy">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery-b">
                    <img src="assets/img/gallery/main6.webp" alt="ฝากส่งบิ๊กไบค์ ขนส่ง Big Bike" loading="lazy">
                </div>
            </div>

        </div>
    </div>
</div>
<!-- End Gallery Area -->

<!-- <app-reviews></app-reviews> -->

<!-- <app-faq-detail></app-faq-detail> -->

<!-- Frequently Area -->
<div class="frequently-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="frequently-accrodion">
                    <h3>คำถามที่พบบ่อย</h3>
                    <p>มีคำถามเพิ่มเติมใช่มั้ย? <a routerLink="/faq">ดูคำถามที่พบบ่อยของเราได้ที่นี่</a></p>
                    <div id="accordion">
                        <div class="accrodion-drawer">
                            <h3>บริการรถรับจ้างขนของ คิดราคายังไง? <i class='bx bx-chevron-right'></i></h3>
                            <div class="drawer is-hidden">
                                <p>ปกติแล้ว บริการรถรับจ้างของเราจะมีวิธีการขนส่งอยู่2แบบครับ คือแบบเหมาคัน กับแบบฝากส่ง
                                    ซึ่งแบบเหมาคันเมื่อลูกค้าจองแล้วจะสามารถจัดส่งได้ทันทีครับ ส่วนในแบบฝากส่งนั้น
                                    ทางเราจะคิดตามปริมาณสินค้า ขนาด น้ำหนักจริง ระยะทาง เพื่อให้ได้ราคาถูกที่สุดครับ
                                    แต่ระยะเวลาในการขนส่งก็จะต่างออกไป 2-3 วัน
                                    ขึ้นอยู่กับระยะทางหรือจำนวนคิวรถในช่วงนั้นๆครับ</p>
                            </div>
                        </div>

                        <div class="accrodion-drawer">
                            <h3>หจก. 4 คิงส์ ขนส่งตั้งอยู่ที่ไหน?<i class='bx bx-chevron-right'></i></h3>
                            <div class="drawer is-hidden">
                                <p>สำนักงานใหญ่ของเราอยู่ในจังหวัดสมุทรปราการครับ
                                    ส่วนทีมงานขนส่งเรามีให้บริการคุณลูกค้าทั่วประเทศ 77 จังหวัดเลยครับ มั่นใจ
                                    ปลอดภัยหายห่วงครับ หรือหากทางลูกค้าอยากทราบรีวิวลูกค้าท่านอื่นๆ
                                    สามารถติดตามได้ผ่านช่องทาง social media, Facebook page, Youtube, Tiktok ได้เลยครับ
                                </p>
                            </div>
                        </div>
                        <div class="accrodion-drawer">
                            <h3>หจก. 4 คิงส์ ขนส่งมีบริการอะไรบ้าง?<i class='bx bx-chevron-right'></i></h3>
                            <div class="drawer is-hidden">
                                <p>ทางเรามีบริการขนส่งทั่วประเทศครอบคลุมการขนส่งทุกชนิดเลยครับ ไม่ว่าจะเป็นขนของ
                                    ย้ายบ้าน ย้ายหอ ย้ายครัว ย้ายตู้เย็น ย้ายคอนโด ย้ายออฟฟิศ ฝากส่งเฟอร์นิเจอร์
                                    ฝากส่งสินค้าขนาดเล็ก-ใหญ่ ฝากส่งเครื่องจักร ส่งรถมอไซค์ ขนย้ายบิ๊กไบค์
                                    ขนย้ายบูธแสดงสินค้า พร้อมห่อหุ้มกันรอยฟรี ยกรถเสีย ฝากส่งสัตว์เลี้ยงชนิดต่างๆ
                                    โดยที่ทางเราก็มีรถพร้อมให้บริการหลายประเภทด้วยครับ ไม่ว่าจะเป็น รถกระบะคอก
                                    รถกระบะตู้ทึบ รถกระบะตู้ทึบจัมโบ้ รถหกล้อหรือรถเฮี๊ยบ
                                    เรียกได้ว่าครบเครื่องทุกการขนส่งจริงๆครับ </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="frequently-image">
                    <div class="frequently-text">
                        <h3>ให้งานขนย้ายเป็นเรื่องง่าย โทรหาเราได้เลย!</h3>
                        <p>เรามีทีมงานพร้อมให้บริการคุณทุกพื้นที่ ทั่วประเทศ รับ-ส่งถึงหน้าบ้าน บริการตลอด 24 ชม.
                            เรื่องขนส่ง อุ่นใจ หายห่วง</p>
                        <a href="tel:0917762068">091-776-2068</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Frequently Area -->

<app-short-contact></app-short-contact>