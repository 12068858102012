<!-- Star Faq Area -->
<div class="faq-area pt-100">
    <div class="container">
        <div class="section-title">
            <span>FAQ</span>
            <h2>คำถามที่พบบ่อย</h2>
        </div>

        <div class="faq-contant">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <ul class="accordion">
                        <li *ngFor='let item of questions'>
                            <h3 class="title">{{item.question}}</h3>
                            <div class="accordion-content">
                                <p>{{item.answer}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Faq Area -->