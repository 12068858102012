<!-- Page banner Area -->
<div class="page-banner" style="background-image: url({{backgroundImage}});">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>บริการของเรา</h2>
                    <ul>
                        <li><a routerLink="/">หน้าแรก</a></li>
                        <li>{{serviceName}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Single Service Area -->
<div class="single-services-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text"> 
                    <!-- <div class="service-image">
                        <img src="assets/img/banner/banner4.jpg" alt="รถรับจ้าง">
                    </div> -->
                    <h2>{{serviceName}}</h2>
                    <div [innerHTML]=serviceText style="font: 1.1rem Prompt;"></div>
                </div> 
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">เมนูลัด - บริการของเรา</h3>
                    <ul>
                        <li><a routerLink="/services-details/housemover"><i class='bx bxs-home'></i>รถรับจ้างขนของ ย้ายบ้านราคาถูก</a></li>
                        <li><a routerLink="/services-details/bigbiketransport"><i class='bx bx-cycling'></i>รถรับจ้างขนย้ายบิ๊กไบค์ ส่งมอไซค์ไปต่างจังหวัด</a></li>
                        <li><a routerLink="/services-details/petdelivery"><i class='bx bxs-dog'></i>บริการรับส่งสัตว์เลี้ยง ขนย้ายสัตว์เลี้ยง</a></li>
                        <li><a routerLink="/services-details/parceltransport"><i class='bx bx-package'></i>บริการรับส่งสินค้าทั่วไป อุตสาหกรรม</a></li>
                        <li><a routerLink="/services-details/exhibition"><i class='bx bx-package'></i>บริการขนย้ายงานจัดแสดงสินค้า ย้ายบูธออกงานอีเว้นท์</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="service-footer">
            <div class="service-tags">
                <span><i class='bx bx-purchase-tag'></i></span>
                <a routerLink="/services-details/housemover">รถรับจ้างขนของ ย้ายบ้าน</a>,
                <a routerLink="/services-details/bigbiketransport">ขนย้ายมอไซค์ข้ามจังหวัด</a>,
                <a routerLink="/services-details/petdelivery">รับ-ส่งสัตว์เลี้ยง</a>,
                <a routerLink="/services-details/parceltransport">ส่งสินค้า-พัสดุ</a>
                <a routerLink="/services-details/exhibition">ย้ายงานจัดแสดงสินค้า ย้ายบูธ</a>
            </div>

            <div class="service-share">
                <ul class="social">
                    <li><span>แชร์ให้เพื่อนเลย:</span></li>
                    <li><a href="https://www.facebook.com/sharer/sharer.php?u={{shareUrl}}&quote={{shareDescription}}" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://twitter.com/intent/tweet?url={{shareUrl}}&text={{shareDescription}}" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Single Service Area -->

<div class="gallery-area pt-100">
    <div class="container">
        <div class="choose-us-text">
            <div class="section-title">
                <span>ลูกค้าส่วนหนึ่งของเรา</span>
                <h2>ติดตามอัพเดตผลงานล่าสุดของเราได้ทั้งช่องทาง <a href="https://www.facebook.com/%E0%B8%AB%E0%B8%88%E0%B8%814-%E0%B8%84%E0%B8%B4%E0%B8%87%E0%B8%AA%E0%B9%8C-%E0%B8%82%E0%B8%99%E0%B8%AA%E0%B9%88%E0%B8%87-107051121915840/"
                    target="_blank" alt="เพจ facebook หจก 4คิงส์ จำกัด" aria-label="our facebook page"><i class='bx bxl-facebook'></i></a>&nbsp;  
                    <a href="https://www.youtube.com/channel/UCAmlp7p0RKD4oFRQO8DKMcg" target="_blank"
                    alt="ช่อง youtube หจก 4คิงส์ จำกัด" aria-label="our youtube page" ><i class='bx bxl-youtube'></i></a>&nbsp;  
                    <a href="https://www.tiktok.com/@4kingtransport" target="_blank"
                                alt="ช่อง tiktok หจก 4คิงส์ จำกัด" aria-label="our tiktok page"><i class='bx bxl-tiktok'></i></a>
                </h2>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor='let item of images'>
                <div class="floza-gallery">
                    <img [src]=item.src [alt]=item.alt loading="lazy">
                </div>
            </div>
        </div>
    </div>
</div>
<app-faq-detail></app-faq-detail>
<app-short-contact></app-short-contact>
