<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>ผลงานของเรา</h2>
                    <ul>
                        <li><a routerLink="/">หน้าแรก</a></li>
                        <li>ผลงานของเรา</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="text-center pt-100 pb-70">
    <h2>สำหรับข้อมูลข่าวสารล่าสุดของทาง หจก 4 คิงส์ ขนส่ง ลูกค้าสามารถติดตามเราที่ facebook page ด้านล่างได้เลยค่ะ</h2>
    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100078750972608&tabs=timeline&width=500&height=800&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="500" height="800" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</div>

<!-- Gallery Area -->
<div class="gallery-area pb-70 pt-100">
    <div class="container">
        <div class="gallery-title">
            <h2>ลูกค้าส่วนหนึ่งของเรา</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/house12.webp" alt="ลูกค้าของเรา" loading="lazy">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/house12.webp"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/industry12.webp" alt="ลูกค้าของเรา" loading="lazy">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/industry12.webp"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/bike2.webp" alt="ลูกค้าของเรา" loading="lazy">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/bike2.webp"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/industry4.webp" alt="ลูกค้าของเรา" loading="lazy">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/industry4.webp"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/bike1.webp" alt="ลูกค้าของเรา" loading="lazy">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/bike1.webp"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/bike6.webp" alt="ลูกค้าของเรา" loading="lazy">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/bike6.webp"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/industry6.webp" alt="ลูกค้าของเรา" loading="lazy">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/industry6.webp"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/industry13.webp" alt="ลูกค้าของเรา" loading="lazy">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/industry13.webp"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="floza-gallery">
                    <img src="assets/img/gallery/pet7.webp" alt="ลูกค้าของเรา" loading="lazy">
                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/gallery/pet7.webp"><i class='bx bx-show-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Gallery Area -->
<app-faq-detail></app-faq-detail>
<app-short-contact></app-short-contact>
