<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row">
                <div class="col-lg-8 col-md-12" style="margin: auto; text-align: center;">
                    <h2 style="color: aliceblue; ">
                        สอบถามรายละเอียดหรือประเมินราคากับเราตอนนี้ได้เลย</h2>
                </div>
                <div class="col-lg-4 col-md-12">
                    <!-- <a href="tel:0917762068" class="default-btn-one">กดโทรได้เลยครับ!</a> -->
                    <a href="https://lin.ee/GrZqXTl"><img src="assets/img/line.gif" alt="รถรับจ้างขนของ พร้อมคนยก"
                            class="linebtn linebtncenter"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->