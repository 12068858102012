import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-detail',
  templateUrl: './faq-detail.component.html',
  styleUrls: ['./faq-detail.component.scss']
})
export class FaqDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  public questions = [
    {
      "question": "บริการของทางหจก. 4 คิงส์ มีอะไรบ้าง? มีแค่บริการขนย้ายของหรือเปล่า?",
      "answer": "บริการขนย้ายของหรือบริการรถกระบะรับจ้างขนของของเราก็จะมีตั้งแต่ขนย้ายมอไซค์ ย้ายบ้านย้ายหอ ขนย้ายโซฟาขนย้ายตู้เย็น ขนของ ส่งของ ฝากส่งสินค้าชิ้นเล็ก-ใหญ่ หรือแม้กระทั่งส่งสินค้าอุตสาหกรรมต่างๆ หรือจะส่งสัตว์เลี้ยงทางเราก็ยินดีให้บริการครับ สามารถเลือกตามแต่ทางลูกค้าต้องการเลยครับ (แล้วก็ไม่รับส่งสิ่งของผิดกฏหมายนะครับ)"
    },
    {
      "question": "ราคาเหมาคิดยังไง?",
      "answer": "ทางเราจะมีลักษณะการขนส่ง 2 แบบ คือแบบฝากส่งและแบบเหมาคัน โดยทางเราจะมีการคำนวณจากปริมาณสินค้าหรือระยะทางให้ครับ โทรมาคุยกันก่อนได้ สอบถามรายละเอียดฟรีครับ"
    },
    {
      "question": "รับจ้างขนวัวขนควายมั้ย?",
      "answer": "เนื่องจากการขนวัวขนควายต้องใช้รถเฉพาะในการขน ทางเรายังไม่ได้เปิดรับบริการตรงนี้ครับ"
    },
    {
      "question": "สัตว์ประเภทไหนที่ทางหจก. 4 คิงส์ไม่รับจ้างฝากส่ง?",
      "answer": "ปกติแล้วทางเราะรับฝากส่งสัตว์เลี้ยงทั่วไปเช่น สุนัข แมว หนู กระรอก กระต่ายหรือประเภทสัตว์เล็กๆ ไปถึงขนาดกลางนะครับ ส่วนสัตว์ที่เราไม่รับฝากส่งก็จะเป็นพวกสัตว์ผิดกฎหมายทุกชนิด เช่น สัตว์สงวนต่างๆ รวมทั้งสัตว์ที่ป่วยเป็นโรคครับ"
    },
    {
      "question": "ส่งหมา แมวทางรถทัวร์กับส่งทางเราต่างกันยังไง?",
      "answer": "ส่งน้องหมา แมวหรือสัตว์เลี้ยงอื่นๆกับทางเรา ทางเรามีบริการดูแลตลอดการเดินทางครับ โดยน้องหมาน้องแมวจะได้นั่งหน้าไปกับพี่คนขับเลยครับ"
    },
    {
      "question": "ส่งแมวไปต่างจังหวัดต้องทำยังไงบ้าง?",
      "answer": "เตรียมน้ำอาหารให้พร้อม แล้วไปด้วยกันกับเราเลยครับ"
    },
    {
      "question": "คิดราคาค่ายกของหนักมั้ย?",
      "answer": "กรณีใช้คนยกของหนักอาจจะมีบวกเพิ่มครับ ลองสอบถามก่อนได้ครับ"
    },
    {
      "question": "มีบริการส่งแบบ ems มั้ย?",
      "answer": "ทางเรายังไม่มีบริการส่งพัสดุแบบ ems ครับ"
    },
    {
      "question": "มีบริการเก็บเงินปลายทางมั้ย?",
      "answer": "มีครับสามารถชำระปลายทางได้ครับ"
    },
    {
      "question": "มีบริการส่งพัสดุทางมอเตอร์ไซค์มั้ย?",
      "answer": "ไม่มีครับการส่งพัสดุทางมอเตอร์ไซค์อาจจะต้องส่งทางไปรษณีย์ครับเพราะของทางเราเป็นขนส่งชนิดสินค้าชิ้นใหญ่ครับ"
    },
    {
      "question": "มีบริการยกรถเสียมั้ย?",
      "answer": "มีครับ บริการรถรับจ้างของเรารองรับบริการยกรถเสียด้วยครับ สามารถแจ้งสถานที่เข้ารับพร้อมเบอร์โทรไว้ให้ทางเราได้เลยครับ ทางเราพร้อมจะติดต่อกลับทันทีครับ"
    },
    {
      "question": "ขนส่งมอไซค์ไปต่างจังหวัด กับทาง หจก 4คิงส์ ดียังไง?",
      "answer": "บริการส่งมอไซค์ไปต่างจังหวัดของทางหจก. 4คิงส์ขนส่ง ทางเรายินดีบริการรับส่งถึงหน้าบ้านเลยครับ หรือสามารถนัดจุดหมายที่คุณลูกค้าสะดวกก็ได้ครับ"
    },
    {
      "question": "ประเภทรถรับจ้างที่ทางหจก. 4คิงส์ขนส่ง มีให้บริการมีอะไรบ้าง?",
      "answer": "ทางหจก 4คิงส์ขนส่งมีรถรับจ้างขนของให้บริการค่อนข้างหลากหลายครับ ไม่ว่าจะเป็นรถกระบะคอก รถกระบะตู้ทึบ รถบรรทุก4ล้อใหญ่ รถบรรทุก6ล้อคอก หรือจะเป็นรถเฮี๊ยบทางเราก็มีให้บริการครับ"
    },
    {
      "question": "ถ้าจะย้ายของจากคอนโดมีคนช่วยยกของกับถอดประกอบเฟอร์นิเจอร์ให้มั้ย?",
      "answer": "บริการรถรับจ้างขนของของทางหจก. 4คิงส์ขนส่งมีพนักงานขนของรวมถึงถอดประกอบเฟอร์นิเจอร์บริการพร้อมครับ หายห่วงได้ครับ"
    },
    {
      "question": "มีบริการขนของย้ายออฟฟิศมั้ย?",
      "answer": "บริการรถรับจ้างขนของของเราครอบคลุมการขนส่งทุกรูปแบบรวมไปถึงบริการย้ายออฟฟิศครับ สะดวกนัดวันได้เลยครับ"
    }
  ]
}
