import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
// import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { TrucksComponent } from './trucks/trucks.component';
// import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
// import { HomeTwoComponent } from './components/pages/home-two/home-two.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent, data: { breadcrumb: 'หน้าแรก' }},
    // {path: 'home-two', component: HomeTwoComponent},
    // {path: 'home-three', component: HomeThreeComponent},
    {path: 'about', component: AboutComponent, data: { breadcrumb: 'เกี่ยวกับเรา' }},
    // {path: 'team', component: TeamComponent},
    // {path: 'testimonials', component: TestimonialsComponent},
    // {path: 'gallery', component: GalleryComponent},
    {path: 'faq', component: FaqComponent, data: { breadcrumb: 'คำถามที่พบบ่อย', metaDescription: 'หจก. 4คิงส์ ขนส่ง faq', metaKeyword: 'หจก. 4คิงส์ ขนส่ง trucks' }},
    // {path: 'sign-in', component: SignInComponent},
    // {path: 'sign-up', component: SignUpComponent},
    // {path: 'privacy-policy', component: PrivacyPolicyComponent},
    // {path: 'terms-conditions', component: TermsConditionsComponent},
    // {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'error', component: ErrorComponent, data: { breadcrumb: 'Not Found' }},
    // {path: 'services', component: ServicesComponent},
    // {path: 'services-details', component: ServicesDetailsComponent},
    {path: 'services-details/:id', component: ServicesDetailsComponent, data: { breadcrumb: 'บริการของเรา' }},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'trucks', component: TrucksComponent, data: { breadcrumb: 'ประเภทรถของเรา', metaDescription: 'หจก. 4คิงส์ ขนส่ง trucks', metaKeyword: 'หจก. 4คิงส์ ขนส่ง trucks' }},
    {path: 'contact', component: ContactComponent, data: { breadcrumb: 'ติดต่อเรา', metaDescription: 'หจก. 4คิงส์ ขนส่ง contact', metaKeyword: 'หจก. 4คิงส์ ขนส่ง trucks' }},
    {path: 'portfolio', component: PortfolioComponent, data: { breadcrumb: 'ผลงานของเรา', metaDescription: 'หจก. 4คิงส์ ขนส่ง portfolio', metaKeyword: 'หจก. 4คิงส์ ขนส่ง trucks' }},
    
    // Here add new component
    
    {path: '**', component: ErrorComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }