<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>บทความของเรา</h2>
                    <ul>
                        <li><a routerLink="/">หน้าแรก</a></li>
                        <li>บทความ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Blog Area -->
<div class="blog-area-two ptb-100">
    <div class="container">
        <div class="section-title">
            <span>บทความของเรา</span>
            <h2>ติดตามบทความล่าสุดของเราได้เลย</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Delivery</span>
                        <div class="date">22 August 2020</div>
                        <h3><a routerLink="/blog-details">We ensure you best the quality services</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Transport</span>
                        <div class="date">23 August, 2020</div>
                        <h3><a routerLink="/blog-details">Air cargo may become short-term solution</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog11.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Delivery</span>
                        <div class="date">24 August, 2020</div>
                        <h3><a routerLink="/blog-details">We introduce new boat and flight service</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog12.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Transport</span>
                        <div class="date">25 August, 2020</div>
                        <h3><a routerLink="/blog-details">Goods will be contain in certified safe</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog13.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Delivery</span>
                        <div class="date">26 August, 2020</div>
                        <h3><a routerLink="/blog-details">Introduce new boat service in this spring</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog10.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Transport</span>
                        <div class="date">27 August, 2020</div>
                        <h3><a routerLink="/blog-details">Our customer around the world satisty with it</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>
            
            <!-- Pagination -->
            <div class="col-lg-12 col-md-12">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" routerLink="/blog"><span aria-hidden="true">&laquo;</span></a></li>
                    <li class="page-item active"><a class="page-link" routerLink="/blog">1</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/blog">2</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/blog">3</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/blog"><span aria-hidden="true">&raquo;</span></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Area -->