<!-- Footer Area-->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget">
                    <h3>ห้างหุ้นส่วนจำกัด 4 คิงส์ขนส่ง (4 King Transport)</h3>
                    <p>ผู้ให้บริการขนส่งมืออาชีพ กับบริการรับส่งคุณภาพ พร้อมให้บริการคุณแล้ว ทั่วประเทศ </p>
                    <p>เลขทะเบียนนิติบุคคลเลขที่ <a href="https://www.dataforthai.com/company/0113565002319/"
                            target="_blank" alt="ทะเบียนนิติบุคคล หจก 4คิงส์ จำกัด">0113565002319</a></p>

                    <p>พบกับช่องทางอื่นๆในการติดต่อเราผ่านทางโซเชียลมีเดียที่ลิ้งด้านล่างได้เลย!</p>
                    <ul class="footer-text">
                    </ul>
                    <ul class="footer-socials">
                        <li><a href="https://www.facebook.com/%E0%B8%AB%E0%B8%88%E0%B8%814-%E0%B8%84%E0%B8%B4%E0%B8%87%E0%B8%AA%E0%B9%8C-%E0%B8%82%E0%B8%99%E0%B8%AA%E0%B9%88%E0%B8%87-107051121915840/"
                                target="_blank" alt="เพจ facebook หจก 4คิงส์ จำกัด" aria-label="facebook page"><i
                                    class='bx bxl-facebook'></i></a>
                        </li>
                        <li><a href="https://www.youtube.com/channel/UCAmlp7p0RKD4oFRQO8DKMcg" target="_blank"
                                alt="ช่อง youtube หจก 4คิงส์ จำกัด" aria-label="youtube page"><i
                                    class='bx bxl-youtube'></i></a></li>
                        <li><a href="https://www.tiktok.com/@4kingtransport" target="_blank"
                                alt="ช่อง tiktok หจก 4คิงส์ จำกัด" aria-label="tiktok page"><i
                                    class='bx bxl-tiktok'></i></a></li>
                    </ul>

                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>ข้อมูลเว็บไซต์</h3>

                    <ul class="footer-text">
                        <li><a routerLink="/">หน้าแรก</a></li>
                        <li><a routerLink="/services-details/housemover">บริการขนของย้ายบ้าน ย้ายหอ</a></li>
                        <li><a routerLink="/services-details/bigbiketransport">บริการขนย้ายบิ๊กไบค์</a></li>
                        <li><a routerLink="/services-details/petdelivery">บริการรับส่งสัตว์เลี้ยง</a></li>
                        <li><a routerLink="/services-details/parceltransport">บริการรับส่งสินค้า พัสดุ</a></li>
                        <li><a routerLink="/trucks">ประเภทรถของเรา</a></li>
                        <li><a routerLink="/portfolio">ผลงานของเรา</a></li>
                        <li><a routerLink="/contact">ติดต่อเรา</a></li>
                        <li><a routerLink="/faq">FAQ</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget">
                    <h3>ข้อมูลติดต่อ</h3>
                    <ul class="info-list">
                        <li><i class='bx bxs-location-plus'></i>162/5 สำโรงใต้ 6 สำโรง พระประแดง สมุทรปราการ 10130</li>
                        <li><i class='bx bxl-facebook'></i> <a
                                href="https://www.facebook.com/%E0%B8%AB%E0%B8%88%E0%B8%814-%E0%B8%84%E0%B8%B4%E0%B8%87%E0%B8%AA%E0%B9%8C-%E0%B8%82%E0%B8%99%E0%B8%AA%E0%B9%88%E0%B8%87-107051121915840/"
                                target="_blank" alt="เพจ facebook หจก 4คิงส์ จำกัด">4 King Transport's Facebook Official
                                Page</a></li>
                        <li><i class='bx bxl-youtube'></i> <a
                                href="https://www.youtube.com/channel/UCAmlp7p0RKD4oFRQO8DKMcg" target="_blank"
                                alt="ช่อง youtube หจก 4คิงส์ จำกัด">4 King Transport's Youtube Channel</a></li>
                        <li><i class='bx bxl-tiktok'></i> <a href="https://www.tiktok.com/@4kingtransport"
                                target="_blank" alt="ช่อง tiktok หจก 4คิงส์ จำกัด">4 King Transport's Tiktok Channel</a>
                        </li>
                        <li><i class='bx bxs-envelope'></i> <a
                                href="mailto:fourkingtransport@gmail.com">fourkingtransport&#64;gmail.com</a></li>
                        <li><i class='bx bxs-phone-call'></i> <a href="https://lin.ee/GrZqXTl">Line ID : &#64;4kingtp</a>
                        </li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:0917762068">091-776-2068 : คุณต๋อง</a></li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:021062211">02-106-2211</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <h3>ป้ายกำกับ</h3>
            <p> รถรับจ้าง <a href="https://www.4kingtransport.com/" title="รถกระบะรับจ้าง | รถรับจ้างขนของ"
                    target="_blank" alt="รถรับจ้าง หจก 4 คิงส์ขนส่ง">รถกระบะรับจ้าง</a> รถรับจ้างขนของ พร้อมคนยก
                รับจ้างขนของย้ายหอ รถเฮี๊ยบส่งของ <b>รถรับจ้างขนของ</b> รถกระบะรับจ้าง ขนส่งสัตว์เลี้ยง
                ขนส่งสินค้าอุตสาหกรรม รับย้ายบ้าน รับย้ายครัว <b>ส่งมอไซค์ไปต่างจังหวัด</b> รับส่งบิ๊กไบค์ ย้ายตู้เย็น
                ขนย้ายที่นอน ส่งเฟอร์นิเจอร์ บริการขนส่งข้ามจังหวัด รับส่งสัตว์เลี้ยง ส่งของ <b>รับส่งพัสดุ</b>
                รับจ้างขนส่งเครื่องจักรอุตสาหกรรม บริการเก็บเงินปลายทาง
            </p>
        </div> -->
    </div>
</footer>
<!-- End Footer Area -->

<!-- Footer bottom Area -->
<div class="footer-bottom">
    <div class="container">
        <p style="font-family: Kittithada; font-size: 1.6rem;">ลิขสิทธิ์โดย © <a href="https://www.4kingtransport.com/"
                target="_blank" alt="รถรับจ้าง หจก 4 คิงส์ขนส่ง">4 King Transport </a>2024</p>
    </div>
</div>
<!-- End Footer bottom Area -->

<!-- Go Top -->
<div>
    <a href="https://lin.ee/GrZqXTl"><img src="assets/img/line-icon.png" alt="รถรับจ้างขนของ พร้อมคนยก"
            class="phone"></a>
</div>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>

<!-- End Go Top -->