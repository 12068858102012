<!-- Page banner Area -->
<div class="page-banner bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>ติดต่อเรา</h2>
                    <ul>
                        <li><a routerLink="/">หน้าแรก</a></li>
                        <li>ติดต่อเรา</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Conatct Info -->
<div class="contact-form-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>ช่องทางการติดต่อ</span>
            <h2>หจก. 4คิงส์ ขนส่ง</h2>
        </div>

        <div class="contact-form">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="contact-info">
                            <i class='bx bxs-phone'></i>
                            <h4>กดโทรได้เลย</h4>
                            <p><a href="tel:0917762068">091-776-2068</a></p>
                            <p><a href="tel:0947748458">094-774-8458</a></p>
                            <p><a href="tel:021062211">02-106-2211</a></p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="contact-info">
                            <i class='bx bxs-phone'></i>
                            <h4>Line</h4>
                            <br>
                            <p><a href="https://lin.ee/GrZqXTl">Line ID : &#64;4kingtp</a></p>
                            <br>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="contact-info">
                            <i class='bx bxs-location-plus'></i>
                            <h4>ที่อยู่ (สำนักงานใหญ่)</h4>
                            <p>162/5 สำโรงใต้ 6 สำโรง พระประแดง</p>
                            <p>สมุทรปราการ 10130</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="contact-info">
                            <i class='bx bxs-envelope'></i>
                            <h4>อีเมล์</h4>
                            <br>
                            <p><a href="mailto:fourkingtransport@gmail.com">fourkingtransport&#64;gmail.com</a></p>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <h2>หรือติดตามอัพเดตผลงานล่าสุดของเราได้ผ่านช่องทางโซเชียลได้เลย <a
                                href="https://www.facebook.com/%E0%B8%AB%E0%B8%88%E0%B8%814-%E0%B8%84%E0%B8%B4%E0%B8%87%E0%B8%AA%E0%B9%8C-%E0%B8%82%E0%B8%99%E0%B8%AA%E0%B9%88%E0%B8%87-107051121915840/"
                                target="_blank" alt="เพจ facebook หจก 4คิงส์ จำกัด" aria-label="our facebook page" class="facebook-icon"><i
                                    class='bx bxl-facebook'></i></a>&nbsp;
                            <a href="https://www.youtube.com/channel/UCAmlp7p0RKD4oFRQO8DKMcg" target="_blank"
                                alt="ช่อง youtube หจก 4คิงส์ จำกัด" aria-label="our youtube page" class="youtube-icon"><i
                                    class='bx bxl-youtube'></i></a>&nbsp;
                            <a href="https://www.tiktok.com/@4kingtransport" target="_blank"
                                alt="ช่อง tiktok หจก 4คิงส์ จำกัด" aria-label="our tiktok page" class="tiktok-icon"><i
                                    class='bx bxl-tiktok'></i></a>
                        </h2>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- End Conatct Info -->

<app-provinces></app-provinces>
<!-- Contact Area -->
<!-- <div class="contact-form-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" id="name" required
                                placeholder="Your name">
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="email" name="email" class="form-control" id="email" required
                                placeholder="Your email address">
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                                placeholder="Your Subject">
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" class="form-control" id="phone_number" required
                                placeholder="Your phone number">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea name="message" id="message" class="form-control" cols="30" rows="6" required
                                placeholder="Write your message..."></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn-one">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div> -->
<!-- Contact Area -->
<app-faq-detail></app-faq-detail>
<app-short-contact></app-short-contact>