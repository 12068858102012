<div class="gallery-area pt-100">
    <div class="container">
        <div class="choose-us-text">
            <div class="section-title">
                <span>พื้นที่ให้บริการ</span>
                <h2>พื้นที่ให้บริการรถรับจ้างของหจก. 4คิงส์ ขนส่ง</h2>
            </div>
        </div>

        <div class="row">
            <div class="regions">
                <div *ngFor="let region of regions" class="region">
                    <h3>{{ region.name }}</h3>
                    <p class="province-list">
                        <span *ngFor="let province of region.provinces">{{ province }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>