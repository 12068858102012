import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  

  constructor(private title: Title, private meta: Meta) {}

  updateTitle(title: string) {
    if (title) {
      this.title.setTitle(title);
      this.meta.updateTag({ name: 'og:title', content: title });
    }
  }

  updateType(type: string) {
    if (type !== "") {
      this.meta.updateTag({ name: 'og:type', content: type });
    }
  }

  updateDescription(description: string) {
    if (description !== "") {
      this.meta.updateTag({ name: 'description', content: description });
      this.meta.updateTag({ name: 'og:description', content: description });
    }
  }

  updateKeywords(keywords: string) {
    if (keywords !== "") {
      this.meta.updateTag({ name: 'keywords', content: keywords });
    }
  }

  updateImage(image: string) {
    if (image !== "") {
      this.meta.updateTag({ name: 'og:image', content: image });
    }
  }

}
