<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>ประเภทรถของเรา</h2>
                    <ul>
                        <li><a routerLink="/">หน้าแรก</a></li>
                        <li>ประเภทรถของเรา</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Services Area -->
<div class="services-area services-two pb-100">
    <div class="container">
        <div class="section-title">
            <span>รถรับจ้างของเรา</span>
            <h2>ประเภทรถรับจ้างที่เรามีให้บริการ</h2>
        </div>

        <div class="services-slider owl-carousel owl-theme">
            <div class="service-card">
                <div class="image-container">
                    <img src="assets/img/services/1.webp" alt="รถกระบะตู้ทึบรับจ้าง" loading="lazy">
                </div>
                <h3>รถกระบะตู้ทึบ</h3>
                <p>ขนาด 1.50 x 2.20 x 2.10 ม.</p>
                <p>น้ำหนักที่บรรทุกไม่เกิน 1,500 กก.</p>
            </div>

            <div class="service-card">
                <div class="image-container">
                    <img src="assets/img/services/3.webp" alt="รถกระบะคอกรับจ้าง" loading="lazy">
                </div>
                <h3>รถกระบะคอก</h3>
                <p>ขนาด 1.65 x 2.30 x 2.10 ม.</p>
                <p>น้ำหนักที่บรรทุกไม่เกิน 2,500 กก.</p>
            </div>

            <div class="service-card">
                <div class="image-container">
                    <img src="assets/img/services/2.webp" alt="รถบรรทุก 4 ล้อใหญ่รับจ้าง" loading="lazy">
                </div>
                <h3>รถบรรทุก 4 ล้อใหญ่</h3>
                <p>ขนาด 1.80 x 3.20 x 2.30 ม.</p>
                <p>น้ำหนักที่บรรทุกไม่เกิน 4,000 กก.</p>
            </div>

            <div class="service-card">
                <div class="image-container">
                    <img src="assets/img/services/4.webp" alt="รถ6ล้อขนส่ง" loading="lazy">
                </div>
                <h3>รถบรรทุก 6 ล้อคอก</h3>
                <p>ขนาด 2.30 x 6.50 x 2.80 ม.</p>
                <p>น้ำหนักที่บรรทุกไม่เกิน 6,000 กก.</p>
            </div>

            <div class="service-card">
                <div class="image-container">
                    <img src="assets/img/services/5.webp" alt="รถเฮี๊ยบรับจ้าง" loading="lazy">
                </div>
                <h3>รถเฮี๊ยบ</h3>
                <p>ขนาด 2.30 x 12.00 x 2.80 ม.</p>
                <p>น้ำหนักที่บรรทุกไม่เกิน 15,000 กก.</p>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<div class="about-area-two ptb-100">
    <div class="container">
        <div class="row align-items-center pb-100">
            <div class="col-lg-6 col-md-12">
                <div class="about-contant-others">
                    <div class="section-title">
                        <span>รถกระบะคอก</span>
                        <h2>รถกระบะคอกเหมาะกับการขนส่งแบบไหน ?</h2>
                    </div>

                    <div class="about-two-text">
                        <p>รถกระบะคอกเหมาะสำหรับการขนส่งสินค้าที่ต้องการระบายอากาศหรือการบรรทุกสิ่งของที่มีขนาดใหญ่และน้ำหนักเบา เช่น ผลิตผลทางการเกษตร เฟอร์นิเจอร์ หรือสิ่งของที่ไม่ต้องการการปกปิด เช่น เครื่องจักรเบาและวัสดุก่อสร้าง.</p>
                        <p>ด้วยความสามารถในการขนส่งของที่มีขนาดใหญ่กว่ารถกระบะปกติ รถกระบะคอกยังเหมาะสำหรับการขนย้ายสิ่งของที่ต้องการความระมัดระวังในการบรรจุและเคลื่อนย้าย.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pt-100">
                <div>
                    <br>
                    <img src="assets/img/services/truck1.webp" alt="รถกระบะคอก" loading="lazy">
                </div>
            </div>
        </div>
        <div class="row align-items-center pb-100">
            <div class="col-lg-6 col-md-12">
                <div class="about-contant-others">
                    <div class="section-title">
                        <span>รถกระบะตู้ทึบ</span>
                        <h2>รถกระบะตู้ทึบเหมาะกับการขนส่งแบบไหน ?</h2>
                    </div>

                    <div class="about-two-text">
                        <p>รถกระบะตู้ทึบเหมาะสำหรับการขนส่งสินค้าที่ต้องการการปกป้องจากสภาพอากาศ เช่น ฝนหรือแดดจ้า และต้องการความปลอดภัยสูง สินค้าที่มักใช้รถประเภทนี้ ได้แก่ อุปกรณ์อิเล็กทรอนิกส์ สินค้าที่บรรจุในกล่อง หรือสินค้าที่ต้องการการจัดเก็บอย่างเป็นระเบียบ.</p>
                        <p>นอกจากนี้ รถกระบะตู้ทึบยังเหมาะสำหรับการขนย้ายสินค้าที่มีมูลค่าสูงหรือต้องการความปลอดภัยจากการมองเห็นของบุคคลภายนอก.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pt-100">
                <div>
                    <br>
                    <img src="assets/img/services/truck2.webp" alt="รถกระบะตู้ทึบ" loading="lazy">
                </div>
            </div>
        </div>
        <div class="row align-items-center pb-100">
            <div class="col-lg-6 col-md-12">
                <div class="about-contant-others">
                    <div class="section-title">
                        <span>รถ 4 ล้อจัมโบ้ตู้ทึบ</span>
                        <h2>รถ 4 ล้อจัมโบ้ตู้ทึบเหมาะกับการขนส่งแบบไหน ?</h2>
                    </div>

                    <div class="about-two-text">
                        <p>รถ 4 ล้อจัมโบ้ตู้ทึบเป็นตัวเลือกที่ดีสำหรับการขนส่งสินค้าที่มีขนาดใหญ่กว่าที่รถกระบะทั่วไปสามารถบรรทุกได้ สินค้าที่มักจะขนส่งด้วยรถประเภทนี้ ได้แก่ เฟอร์นิเจอร์ขนาดใหญ่ เครื่องใช้ไฟฟ้า หรือสินค้าที่ต้องการพื้นที่มากในการขนส่ง.</p>
                        <p>ด้วยความจุที่มากขึ้น รถ 4 ล้อจัมโบ้ตู้ทึบยังเหมาะสำหรับการขนส่งสินค้าที่ต้องการการจัดวางที่ปลอดภัยและมั่นคงในระหว่างการขนส่ง.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pt-100">
                <div>
                    <br>
                    <img src="assets/img/services/truck3.webp" alt="รถ 4 ล้อจัมโบ้ตู้ทึบ" loading="lazy">
                </div>
            </div>
        </div>
        <div class="row align-items-center pb-100">
            <div class="col-lg-6 col-md-12">
                <div class="about-contant-others">
                    <div class="section-title">
                        <span>รถ 4 ล้อใหญ่จัมโบ้</span>
                        <h2>รถ 4 ล้อใหญ่จัมโบ้เหมาะกับการขนส่งแบบไหน ?</h2>
                    </div>

                    <div class="about-two-text">
                        <p>รถ 4 ล้อใหญ่จัมโบ้เหมาะสำหรับการขนส่งสินค้าที่มีน้ำหนักมากและต้องการพื้นที่ในการบรรทุกมาก เช่น เครื่องจักรขนาดใหญ่ สินค้าก่อสร้าง หรือสินค้าที่ต้องการการขนส่งระยะไกลโดยไม่ต้องการหยุดพักบ่อยๆ.</p>
                        <p>รถประเภทนี้มักใช้สำหรับการขนส่งสินค้าที่มีขนาดและน้ำหนักมากกว่ารถ 4 ล้อปกติ ทำให้เหมาะสำหรับการใช้งานในอุตสาหกรรมหนักหรือการขนส่งขนาดใหญ่.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pt-100">
                <div>
                    <br>
                    <img src="assets/img/services/truck4.webp" alt="รถ 4 ล้อใหญ่จัมโบ้" loading="lazy">
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-contant-others">
                    <div class="section-title">
                        <span>รถ 6 ล้อตู้ทึบ</span>
                        <h2>รถ 6 ล้อตู้ทึบเหมาะกับการขนส่งแบบไหน ?</h2>
                    </div>

                    <div class="about-two-text">
                        <p>รถ 6 ล้อตู้ทึบเป็นตัวเลือกที่ยอดเยี่ยมสำหรับการขนส่งสินค้าที่มีน้ำหนักมากและต้องการการปกป้องจากสภาพอากาศ สินค้าที่มักจะใช้รถประเภทนี้ในการขนส่งได้แก่ สินค้าอุตสาหกรรม วัสดุก่อสร้าง หรือสินค้าที่ต้องการการจัดเก็บที่มั่นคงและปลอดภัย.</p>
                        <p>ด้วยขนาดและความสามารถในการบรรทุกที่สูงขึ้น รถ 6 ล้อตู้ทึบเหมาะสำหรับการขนส่งสินค้าที่ต้องการพื้นที่บรรทุกขนาดใหญ่และการปกป้องสินค้าจากสภาพแวดล้อมภายนอก.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pt-100">
                <div>
                    <br>
                    <img src="assets/img/services/truck5.webp" alt="รถ 6 ล้อตู้ทึบ" loading="lazy">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-reviews></app-reviews> -->
<app-provinces></app-provinces>
<app-faq-detail></app-faq-detail>
<app-short-contact></app-short-contact>