<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>404 Error Page</h2>
                    <ul>
                        <li><a routerLink="/">หน้าแรก</a></li>
                        <li>404 Error</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- 404 Error Area -->
<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error">
                    <img class="error-image" src="assets/img/error.png" alt="image">
                    <h2>ไม่พบหน้าที่คุณค้นหา</h2>
                    <div class="error-btn">
                        <a routerLink="/">กลับไปหน้าแรก</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End 404 Error Area -->
<app-short-contact></app-short-contact>
<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Newsletter Area -->