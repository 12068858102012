<!-- Header Area -->
<header class="header-area">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <h1 style="color: white; font-size: 0.9rem;">รถรับจ้างขนของ หจก. 4คิงส์ ขนส่ง รับ-ส่งของทั่วไทย
                    </h1>
                    <ul class="left-info">
                        <li><a href="/contact">ติดต่อเรา</a></li>
                        <li><a href="mailto:fourkingtransport@gmail.com"><i class='bx bxs-envelope'></i>
                                fourkingtransport&#64;gmail.com</a></li>
                        <li><a href="tel:0917762068"><i class='bx bxs-phone-call'></i>091-776-2068 คุณต๋อง</a></li>
                        <!-- <li><a href="tel:0947748458"><i class='bx bxs-phone-call'></i>094-774-8458</a></li> -->
                        <li><a href="https://lin.ee/GrZqXTl"><img src="assets/img/line-icon.png"
                            alt="รถรับจ้างขนของ พร้อมคนยก" style="height: 22px; width: 22px;"> Line ID : &#64;4kingtp</a></li>
                    </ul>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <ul class="right-info">
                        <li class="facebook-icon">
                            <a href="https://www.facebook.com/%E0%B8%AB%E0%B8%88%E0%B8%814-%E0%B8%84%E0%B8%B4%E0%B8%87%E0%B8%AA%E0%B9%8C-%E0%B8%82%E0%B8%99%E0%B8%AA%E0%B9%88%E0%B8%87-107051121915840/" target="_blank" aria-label="our facebook page">
                                <i class='bx bxl-facebook'></i>
                            </a>
                        </li>
                        <li class="youtube-icon">
                            <a href="https://www.youtube.com/channel/UCAmlp7p0RKD4oFRQO8DKMcg" target="_blank" aria-label="our youtube page">
                                <i class='bx bxl-youtube'></i>
                            </a>
                        </li>
                        <li class="tiktok-icon">
                            <a href="https://www.tiktok.com/@4kingtransport" target="_blank" aria-label="our tiktok page">
                                <i class='bx bxl-tiktok'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="navbar-area">
        <div class="floza-responsive-nav">
            <div class="container">
                <div class="floza-responsive-menu">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/test1.png" alt="หจก. 4คิงส์ จำกัด รถรับจ้างขนของ"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="floza-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo3.png"
                            alt="รถรับจ้าง หจก 4 คิงส์ขนส่ง"></a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav ms-auto">
                            <li class="nav-item">
                                <a routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}"><strong>หน้าแรก</strong></a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" rel="nofollow"><strong>บริการขนส่งของเรา</strong><i
                                        class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/services-details/housemover" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"><strong>บริการขนของย้ายบ้าน ย้ายหอ</strong></a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/services-details/bigbiketransport" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"><strong>บริการขนย้ายมอไซค์
                                                บิ๊กไบค์</strong></a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/services-details/petdelivery" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"><strong>บริการรับส่งสัตว์เลี้ยง</strong></a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/services-details/parceltransport" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"><strong>บริการรับส่งสินค้า
                                                พัสดุ</strong></a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/services-details/exhibition" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"><strong>บริการขนย้ายบูธออกงานอีเว้นท์</strong></a>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item"><a routerLink="/trucks" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"><strong>ประเภทรถของเรา</strong></a></li>

                            <li class="nav-item"><a routerLink="/portfolio" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}"><strong>ผลงานของเรา</strong></a></li>
<!-- 
                            <li class="nav-item">
                                <a class="nav-link"><strong>บทความ</strong><i
                                        class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"><strong>บทความทั้งหมด</strong></a>
                                    </li>

                                     <li class="nav-item">
                                        <a routerLink="/services-details/bigbiketransport" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"><strong>บริการขนย้ายมอไซค์
                                                บิ๊กไบค์</strong></a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/services-details/petdelivery" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"><strong>บริการรับส่งสัตว์เลี้ยง</strong></a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/services-details/parceltransport" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"><strong>บริการรับส่งสินค้า
                                                พัสดุ</strong></a>
                                    </li> 
                                </ul>
                            </li> -->

                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}"><strong>ติดต่อเรา</strong></a></li>
                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}"><strong>FAQ</strong></a></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- End Header Area -->