<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>FAQ</h2>
                    <ul>
                        <li><a routerLink="/">หน้าแรก</a></li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<app-faq-detail></app-faq-detail>
<app-short-contact></app-short-contact>
